export function debounce(fn, delay = 500) {
    let timeoutID = null;
    return function () {
        clearTimeout(timeoutID);
        let args = arguments;
        let that = this;
        timeoutID = setTimeout(function () {
            fn.apply(that, args);
        }, delay);
    };
}

export function replaceObjectFields(targetObj, sourceObj) {
    for (const key in sourceObj) {
        targetObj[key] = sourceObj[key];
    }
}

export function generateQueryString(queryObj, { startWithFirstParam = true } = {}) {
    const prefix = startWithFirstParam ? '?' : '&';

    const params = [];
    for (const [key, value] of Object.entries(queryObj)) {
        if (value) params.push(`${key}=${value}`);
    }

    return `${prefix}${params.join('&')}`;
}
