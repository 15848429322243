<template>
    <div>
        <v-row class="text-left">
            <v-col cols="12">
                <v-form class="mr-auto ml-auto ml-md-0">
                    <div class="mb-6">
                        <v-btn :loading="isSelecting" @click="handleFileImport" class="v-btn__primary mr-4"> + Plik </v-btn>
                        <v-btn :loading="isLoadingAlgorithmsData" class="v-btn__primary mr-4" @click="showHashDialogAndGetAlgorithmsData">
                            + Hash
                        </v-btn>
                    </div>

                    <input ref="uploader" class="d-none" type="file" multiple @change="onFileChanged" />

                    <div class="mb-6">
                        <template v-if="selectedFiles.length">
                            <div class="pb-1" v-for="(file, index) in selectedFiles" :key="`${index}_${file.name}`">
                                <v-icon class="pr-2 v-icon_highlight"> mdi-file-outline </v-icon>
                                <span class="pr-2">{{ file.name }}</span>
                                <v-btn icon @click="removeSelectedFile(index)">
                                    <v-icon class="v-icon"> mdi-close </v-icon>
                                </v-btn>
                            </div>
                        </template>

                        <template v-if="hashes.length">
                            <div class="pb-1" v-for="(hash, index) in hashes" :key="`${index}_${hash.name}`">
                                <v-icon class="pr-2 v-icon_highlight"> mdi-pound-box-outline </v-icon>
                                <span class="pr-2">{{ hash.name }}</span>
                                <v-btn icon @click="removeHash(index)">
                                    <v-icon class="v-icon"> mdi-close </v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </div>

                    <v-alert v-if="filesHashesErrors.length" dense outlined type="error" class="py-1 mb-6 text-caption form-block">
                        <div v-for="(error, i) in filesHashesErrors" :key="i">{{ error }}</div>
                    </v-alert>

                    <v-text-field
                        @change="updateFormData"
                        v-model="newJob.name"
                        outlined
                        label="Nazwa:"
                        required
                        class="v-input_required"
                        :color="CSS_COLOR.textHighlight"
                        :error-messages="nameErrors"
                        @input="$v.newJob.name.$touch()"
                        @blur="$v.newJob.name.$touch()"
                    ></v-text-field>

                    <v-select
                        @change="updateFormData"
                        v-model="newJob.attackType.selected"
                        :menu-props="{ nudgeBottom: 40, contentClass: 'v-select__options' }"
                        outlined
                        :color="CSS_COLOR.textHighlight"
                        :items="newJob.attackType.options"
                        label="Rodzaj ataku:"
                        attach
                    ></v-select>

                    <v-select
                        @change="updateFormData"
                        v-model="newJob.attachmenstId"
                        outlined
                        multiple
                        class="v-input_required"
                        :menu-props="{ nudgeBottom: 40, contentClass: 'v-select__options' }"
                        :color="CSS_COLOR.textHighlight"
                        :items="attachments"
                        :loading="isAttachmentsDataLoading"
                        :label="jobAttachmentsLabel[newJob.attackType.selected]"
                        :no-data-text="noDataText"
                        attach
                        :error-messages="attachmenstIdErrors"
                        @input="$v.newJob.attachmenstId.$touch()"
                        @blur="$v.newJob.attachmenstId.$touch()"
                    >
                        <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0">{{ item.name }}</span>
                            <span v-if="index === 1">, {{ item.name }} </span>
                            <span v-if="index === 2" class="grey--text text-caption">&nbsp;(+{{ newJob.attachmenstId.length - 2 }} więcej)</span>
                        </template>
                    </v-select>

                    <v-select
                        @change="updateFormData"
                        class="v-input_half-width"
                        v-model="newJob.priority.selected"
                        :menu-props="{ nudgeBottom: 40, contentClass: 'v-select__options' }"
                        outlined
                        :color="CSS_COLOR.textHighlight"
                        :items="newJob.priority.options"
                        label="Priorytet:"
                        attach
                    ></v-select>

                    <v-textarea
                        @change="updateFormData"
                        class="v-input_full-width"
                        label="Opis:"
                        height="95px"
                        outlined
                        :no-resize="true"
                        v-model="newJob.description"
                    ></v-textarea>
                </v-form>
            </v-col>
        </v-row>

        <Dialog :dialogWidth="'35%'" :title="'Dodaj hash'" :forceActivation="showHashDialog" @dialogClosed="showHashDialog = false">
            <template v-slot:content="{ deactivate }">
                <Job-add-hash @deactivate="addNewHash($event), deactivate()"></Job-add-hash>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { CSS_COLOR } from '../../../_helpers/consts';
import Dialog from '../../global/Dialog.vue';
import JobAddHash from './JobAddHash.vue';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';

export default {
    name: 'JobAddNewForm',

    components: {
        Dialog,
        JobAddHash
    },

    mixins: [validationMixin],

    validations: {
        newJob: {
            name: { required },
            attachmenstId: { required }
        },
        selectedFilesEncoded: {
            required: requiredIf(function () {
                return !this.hashes.length;
            })
        },
        hashes: {
            required: requiredIf(function () {
                return !this.selectedFilesEncoded.length;
            })
        }
    },

    props: {},

    data() {
        return {
            CSS_COLOR,
            newJob: {
                name: '',
                attackType: {
                    options: [
                        { text: 'słownikowy', value: 'dictionary' },
                        { text: 'maskowy', value: 'mask' }
                    ],
                    selected: 'dictionary'
                },
                attachmenstId: [],
                priority: {
                    options: [
                        { text: 'domyślny', value: 'default' },
                        { text: 'wysoki', value: 'high' }
                    ],
                    selected: 'default'
                },
                description: ''
            },

            showHashDialog: false,
            isSelecting: false,

            selectedFiles: [],
            selectedFilesEncoded: [],
            hashes: [],

            jobAttachmentsLabel: {
                mask: 'Pliki maskowe:',
                dictionary: 'Typ słownika'
            },
            noDataText: 'Brak danych'
        };
    },

    computed: {
        storedAttachments() {
            return this.$store.getters.attachmentsItems;
        },

        isAttachmentsDataLoading() {
            return this.$store.getters.isAttachmentsDataLoading;
        },

        attachments() {
            const attackTypeMapper = {
                mask: 'maskfile',
                dictionary: 'dictionary'
            };

            const filteredAttachmentsByType = this.storedAttachments.filter((obj) => {
                return obj.type === attackTypeMapper[this.newJob.attackType.selected] && obj.state.toLowerCase() !== 'deleted';
            });

            filteredAttachmentsByType.forEach((attachment) => {
                attachment.text = attachment.name;
                attachment.value = attachment.id;
            });

            return filteredAttachmentsByType;
        },

        nameErrors() {
            const errors = [];
            if (!this.$v.newJob.name.$dirty) return errors;
            !this.$v.newJob.name.required && errors.push('Nazwa jest wymagana');
            return errors;
        },

        attachmenstIdErrors() {
            const errors = [];
            if (!this.$v.newJob.attachmenstId.$dirty) return errors;
            !this.$v.newJob.attachmenstId.required && errors.push('Pole nie może być puste');
            return errors;
        },

        filesHashesErrors() {
            const errors = [];
            if (!this.$v.selectedFilesEncoded.$dirty && !this.$v.hashes.$dirty) return errors;
            (!this.$v.selectedFilesEncoded.required || !this.$v.hashes.required) && errors.push('Plik lub hash jest wymagany');
            return errors;
        },

        isLoadingAlgorithmsData() {
            return this.$store.getters.isLoadingAlgorithmsData;
        },

        isFormValid() {
            return !this.$v.newJob.$anyError && (this.selectedFilesEncoded.length || this.hashes.length);
        }
    },

    methods: {
        handleFileImport() {
            this.isSelecting = true;

            // After obtaining the focus when closing the FilePicker, return the button state to normal
            window.addEventListener(
                'focus',
                () => {
                    this.isSelecting = false;
                },
                { once: true }
            );

            // Trigger click on the FileInput
            this.$refs.uploader.click();
        },
        onFileChanged(e) {
            this.selectedFiles = [...this.selectedFiles, ...Array.from(e.target.files)];
            this.$refs.uploader.value = null;
            this.encodeSelectedFiles();
        },

        removeSelectedFile(index) {
            this.selectedFiles.splice(index, 1);
            this.encodeSelectedFiles();
        },

        async encodeSelectedFiles() {
            this.selectedFilesEncoded = [];
            for (const file of this.selectedFiles) {
                const fileEncoded = await this.base64Encode(file);
                this.selectedFilesEncoded.push(fileEncoded);
            }

            this.updateFormData();
        },

        addNewFileToStore(item) {
            this.$store.dispatch('addNewJobFile', item);
        },

        addNewHash(item) {
            if (!item) return;

            this.hashes.push(item);
            this.updateFormData();
        },

        removeHash(index) {
            this.hashes.splice(index, 1);
            this.updateFormData();
        },

        updateFormData() {
            this.$emit(
                'formUpdated',
                {
                    name: this.newJob.name,
                    description: this.newJob.description,
                    attack: {
                        type: this.newJob.attackType.selected,
                        attachments: this.newJob.attachmenstId ? this.newJob.attachmenstId : []
                    },
                    files: this.selectedFilesEncoded,
                    hashes: this.hashes,
                    priority: this.newJob.priority.selected
                },
                !this.isFormValid
            );
        },

        async base64Encode(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },

        async showHashDialogAndGetAlgorithmsData() {
            if (!this.storedJobs?.length) {
                await this.$store.dispatch('getAlgorithmsFromApi');
            }
            this.showHashDialog = true;
        }
    },

    watch: {
        'newJob.attackType.selected': function (newVal, oldVal) {
            if (newVal === oldVal) return;
            this.newJob.attachmenstId = [];
            this.$store.dispatch('getAttachmentsFromApi', { perPage: 256, state: 'normal' });
        }
    }
};
</script>
