<template>
    <v-row class="text-left pl-md-16">
        <v-col cols="12" v-if="!showPasswordForgottenPasswordForm">
            <v-form class="form-block mr-auto ml-auto ml-md-0">
                <v-divider class="d-md-none mb-4"></v-divider>
                <h1>Login</h1>
                <v-text-field
                    ref="username"
                    v-model="loginForm.userName"
                    :color="CSS_COLOR.textHighlight"
                    label="Nazwa użytkownika"
                    type="email"
                    outlined
                    :rules="userRules"
                    v-on:keyup.enter="onEnter"
                ></v-text-field>
                <v-text-field
                    v-model="loginForm.password"
                    outlined
                    :color="CSS_COLOR.textHighlight"
                    label="Hasło"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    @keyup.enter="onEnter"
                    :error-messages="passwordError"
                    @input="clearPasswordError"
                ></v-text-field>

                <v-row class="mt-2" align="center" justify="center">
                    <v-col cols="6">
                        <a class="link" @click="formsSwitch">Zapomniałem hasła</a>
                    </v-col>
                    <v-col cols="6" class="text-right">
                        <v-btn class="v-btn__primary" @click="submitLogin">Zaloguj się</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
        <v-col cols="12" v-else>
            <v-form class="form-block mr-auto ml-auto ml-md-0">
                <h1 class="text_primary text-h5">Resetowania hasła</h1>
                <p class="text_primary">W celu zresetowania hasła skontaktuj się z administratorem systemu.</p>

                <v-row align="center" justify="left">
                    <v-col cols="7">
                        <a class="link" @click="formsSwitch">Powrót do logowania</a>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
import { CSS_COLOR } from '../../../_helpers/consts';
import { authorizationService } from '../../../services/authorization.service';

export default {
    name: 'LoginForm',

    mounted() {
        this.focusUsername();
    },

    data: () => ({
        CSS_COLOR,
        userName: '',
        showPassword: false,

        loginFormError: false,

        userRules: [(value) => !!value || 'Wprowadź swoją nazwę użytkownika'],

        loginForm: {
            userName: null,
            password: null
        },

        showPasswordForgottenPasswordForm: false
    }),

    methods: {
        async login(username, password) {
            const response = await authorizationService.login(username, password);

            this.loginFormError = !response;

            if (this.loginFormError) {
                this.loginForm.password = '';
            }
        },

        submitLogin() {
            this.login(this.loginForm.userName, this.loginForm.password);
        },

        formsSwitch() {
            this.showPasswordForgottenPasswordForm = !this.showPasswordForgottenPasswordForm;
        },

        onEnter: function () {
            this.submitLogin();
        },

        clearPasswordError() {
            if (this.passwordError) {
                this.loginFormError = false;
            }
        },
        focusUsername() {
            this.$refs.username.focus();
        }
    },

    computed: {
        passwordError() {
            return !this.loginFormError ? '' : 'Niepoprawna nazwa użytkownika lub hasło';
        }
    }
};
</script>
