import { replaceObjectFields } from '../../_helpers/general';
import { attachmentsService } from '../../services/attachments.service';

const state = () => ({
    attachmentsData: {},
    attachmentsItems: [],
    attachmentsSortBy: '',
    attachmentsSortOrder: '',
    attachmentsSearch: '',
    isAttachmentsDataLoading: false,
    attachmentsLastUpdateTime: '',

    selectedAttachmentForDetails: null,
    isAttachmentNewDataLoading: false,
    isAttachmentRefetching: false,

    attachmentRequestsData: {},
    attachmentRequestsItems: [],
    attachmentRequestsSortBy: '',
    attachmentRequestsSortOrder: '',
    isAttachmentRequestsLoading: false,

    attachmentSchema: {
        created_at: null,
        description: '',
        id: null,
        name: '',
        publication_state: '',
        state: '',
        type: '',
        user: null,
        visibility: ''
    }
});

// getters
const getters = {
    attachmentsData: (state) => {
        return state.attachmentsData;
    },

    attachmentsItems: (state) => {
        return state.attachmentsItems;
    },

    attachmentsSortBy: (state) => {
        return state.attachmentsSortBy;
    },

    attachmentsSortOrder: (state) => {
        return state.attachmentsSortOrder;
    },

    attachmentsSearch: (state) => {
        return state.attachmentsSearch;
    },

    selectedAttachment: (state) => {
        return state.selectedAttachmentForDetails;
    },

    isAttachmentsDataLoading: (state) => {
        return state.isAttachmentsDataLoading;
    },

    isAttachmentNewDataLoading: (state) => {
        return state.isAttachmentNewDataLoading;
    },

    isAttachmentRefetching: (state) => {
        return state.isAttachmentRefetching;
    },

    attachmentsLastUpdateTime: (state) => {
        return state.attachmentsLastUpdateTime;
    },

    attachmentRequestsData: (state) => {
        return state.attachmentRequestsData;
    },

    attachmentRequestsItems: (state) => {
        return state.attachmentRequestsItems;
    },

    attachmentRequestsSortBy: (state) => {
        return state.attachmentRequestsSortBy;
    },

    attachmentRequestsSortOrder: (state) => {
        return state.attachmentRequestsSortOrder;
    },

    isAttachmentRequestsLoading: (state) => {
        return state.isAttachmentRequestsLoading;
    }
};

// actions
const actions = {
    async getAttachmentsFromApi({ commit, dispatch, state: currentState, rootState }, { perPage, state } = {}) {
        const per_page = perPage ?? rootState.global.itemsPerPage;
        const page = currentState.attachmentsData.page;
        const sort = currentState.attachmentsSortBy;
        const order = currentState.attachmentsSortOrder;
        const search = currentState.attachmentsSearch;

        commit('isAttachmentsDataLoadingChange');

        const response = await attachmentsService.getAttachments({ per_page, page, sort, order, search, state });

        if (response.status !== 200) {
            commit('isAttachmentsDataLoadingChange');
            return;
        }

        const { content, ...data } = response.data;

        commit('attachmentsData', data);
        commit('attachmentsItems', content);

        dispatch('autoRefreshResetTimeout');
        commit('isAttachmentsDataLoadingChange');
    },

    async getAttachmentById({ commit, state }, attachmentId) {
        if (state.isAttachmentNewDataLoading) return;

        if (!state.isAttachmentRefetching) {
            commit('setAttachmentNewDataLoading', true);
        }

        const response = await attachmentsService.getAttachmentById(attachmentId);

        if (response.status !== 200) {
            commit('resetAttachmentForDetails');
            commit('setAttachmentRefetching', false);
            commit('setAttachmentNewDataLoading', false);
            return;
        }

        const attachmentData = { ...state.attachmentSchema, ...response.data };

        if (state.isAttachmentRefetching) {
            commit('setAttachmentForDetailsSilently', attachmentData);
        } else {
            commit('setAttachmentForDetails', attachmentData);
        }
        commit('setAttachmentRefetching', false);
        commit('setAttachmentNewDataLoading', false);
    },

    async getAttachmentRequestsFromApi({ commit, state }, attachmentId) {
        if (state.isAttachmentRequestsLoading) return;

        const per_page = 30;
        const page = state.attachmentRequestsData.page;
        const sort = state.attachmentRequestsSortBy;
        const order = state.attachmentRequestsSortOrder;

        commit('isAttachmentRequestsLoadingChange');

        const response = await attachmentsService.getAttachmentRequests(attachmentId, { per_page, page, sort, order });

        if (response.status !== 200) {
            commit('isAttachmentRequestsLoadingChange');
            return;
        }

        const { content, ...data } = response.data;

        commit('attachmentRequestsData', data);
        commit('attachmentRequestsItems', content);

        commit('isAttachmentRequestsLoadingChange');
    },

    resetAttachmentsData({ commit }) {
        commit('resetAttachmentsData');
    },

    setAttachmentsPage({ commit }, page) {
        commit('setAttachmentsPage', page);
    },

    setAttachmentsSortBy({ commit }, fieldName) {
        commit('setAttachmentsSortBy', fieldName);
    },

    setAttachmentsSortOrder({ commit }, order) {
        commit('setAttachmentsSortOrder', order);
    },

    setAttachmentsSearch({ commit }, text) {
        commit('setAttachmentsSearch', text);
    },

    setAttachmentRefetch({ commit }) {
        commit('setAttachmentRefetching', true);
    },

    setAttachmentsLastUpdateTime({ commit }) {
        const dataTime = new Date().toLocaleString();
        commit('setAttachmentsLastUpdateTime', dataTime);
    },

    resetAttachmentRequestsData({ commit }) {
        commit('resetAttachmentRequestsData');
    },

    setAttachmentRequestsPage({ commit }, page) {
        commit('setAttachmentRequestsPage', page);
    },

    setAttachmentRequestsSortBy({ commit }, fieldName) {
        commit('setAttachmentRequestsSortBy', fieldName);
    },

    setAttachmentRequestsSortOrder({ commit }, order) {
        commit('setAttachmentRequestsSortOrder', order);
    },

    isAttachmentRequestsLoadingChange({ commit }) {
        commit('isAttachmentRequestsLoadingChange');
    }
};

// mutations
const mutations = {
    resetAttachmentForDetails(state) {
        state.selectedAttachmentForDetails = null;
    },

    setAttachmentForDetails(state, attachment) {
        state.selectedAttachmentForDetails = attachment;
    },

    setAttachmentForDetailsSilently(state, attachment) {
        replaceObjectFields(state.selectedAttachmentForDetails, attachment);
    },

    attachmentsData(state, payload) {
        state.attachmentsData = payload;
    },

    attachmentsItems(state, payload) {
        state.attachmentsItems = payload;
    },

    resetAttachmentsData(state) {
        state.attachmentsData = {};
        state.attachmentsItems = [];
    },

    setAttachmentsPage(state, payload) {
        state.attachmentsData.page = payload;
    },

    setAttachmentsSortBy(state, payload) {
        state.attachmentsSortBy = payload;
    },

    setAttachmentsSortOrder(state, payload) {
        state.attachmentsSortOrder = payload;
    },

    setAttachmentsSearch(state, payload) {
        state.attachmentsSearch = payload;
    },

    setAttachmentsLastUpdateTime(state, payload) {
        state.attachmentsLastUpdateTime = payload;
    },

    attachmentRequestsData(state, payload) {
        state.attachmentRequestsData = payload;
    },

    attachmentRequestsItems(state, payload) {
        state.attachmentRequestsItems = payload;
    },

    resetAttachmentRequestsData(state) {
        state.attachmentRequestsData = {};
        state.attachmentRequestsItems = [];
    },

    setAttachmentRequestsPage(state, payload) {
        state.attachmentRequestsData.page = payload;
    },

    setAttachmentRequestsSortBy(state, payload) {
        state.attachmentRequestsSortBy = payload;
    },

    setAttachmentRequestsSortOrder(state, payload) {
        state.attachmentRequestsSortOrder = payload;
    },

    isAttachmentRequestsLoadingChange(state) {
        state.isAttachmentRequestsLoading = !state.isAttachmentRequestsLoading;
    },

    isAttachmentsDataLoadingChange(state) {
        if (state.isAttachmentsDataLoading == true) {
            setTimeout(function () {
                state.isAttachmentsDataLoading = false;
            }, 750);
        } else {
            state.isAttachmentsDataLoading = true;
        }
    },

    setAttachmentNewDataLoading(state, payload) {
        if (payload == false) {
            setTimeout(function () {
                state.isAttachmentNewDataLoading = false;
            }, 250);
        } else {
            state.isAttachmentNewDataLoading = true;
        }
    },

    setAttachmentRefetching(state, payload) {
        if (payload == false) {
            setTimeout(function () {
                state.isAttachmentRefetching = false;
            }, 250);
        } else {
            state.isAttachmentRefetching = true;
        }
    }
};

export default {
    state,
    getters,
    actions,
    mutations
    // namespaced: true,
};
